import moment from 'moment/moment';

import { TKS_HOST } from '../common';
import {
  CarPriceCalculateForm,
  CarPriceCalculateFormDefault,
  FormValue,
  KRWtoUSDDefault,
  USDtoRUBDefault,
} from './app.types';

export const calcCarUSDPrice = (
  initCalculate: CarPriceCalculateForm,
  externalForms: FormValue<number>[]
) => {
  const KRWtoUSD = externalForms.find(
    (v) => v.type === 'external' && v.key === 'KRWtoUSD'
  );

  const USDtoRUB = externalForms.find(
    (v) => v.type === 'external' && v.key === 'USDtoRUB'
  );

  if (!KRWtoUSD || !USDtoRUB) {
    throw new Error('KRWtoUSD or USDtoRUB is null');
  }

  const calculate: CarPriceCalculateForm = {
    ...initCalculate,
    KRWtoUSD,
    USDtoRUB,
  };

  const carKoreaPriceKRW =
    calculate.carKoreaPrice.value.value +
    calculate.koreaLogisticPrice.value.value;

  const carKoreaPriceUSD = Math.round(
    carKoreaPriceKRW / calculate.KRWtoUSD.value
  );

  return { calculate, carKoreaPriceUSD };
};

export const calc = (
  initCalculate: CarPriceCalculateForm,
  externalForms: FormValue<number>[]
) => {
  let total: number;

  const { calculate, carKoreaPriceUSD } = calcCarUSDPrice(
    initCalculate,
    externalForms
  );

  const carKoreaPricePlusBankComissionUSD =
    carKoreaPriceUSD * (1 + 0.01 * calculate.koreaBankComission.value);

  const carKoreaPricePlusComissionRUB =
    carKoreaPricePlusBankComissionUSD * calculate.USDtoRUB.value;

  total =
    carKoreaPricePlusComissionRUB +
    calculate.vladivostokLogisticPrice.value.value +
    calculate.spbCommission.value.value +
    calculate.TKS.value.value;

  calculate.total = {
    ...calculate.total,
    value: {
      ...calculate.total.value,
      value: total,
    },
  };

  return calculate;
};

export const getTotalTable = (
  calculate: CarPriceCalculateForm,
  fieldsHided: boolean
): {
  key: string;
  name: string;
  value: string;
  order: number;
}[] => {
  let krw = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'KRW',
  });

  let rub = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  });

  const defaultValues = CarPriceCalculateFormDefault();

  // @ts-ignore
  return (
    Object.entries(calculate)
      .map(([key, v]) => {
        if (v?.name && v?.key) {
          if (v.key === defaultValues.koreaBankComission.key) {
            return undefined;
          }
          if (v.key === KRWtoUSDDefault.key && fieldsHided) {
            return undefined;
          }
          if (v.key === USDtoRUBDefault.key && fieldsHided) {
            return undefined;
          }

          // @ts-ignore
          const order = defaultValues[key]?.order ?? v.order ?? 50;

          if (v.key === defaultValues.description.key) {
            return {
              key: v.key,
              name: v.name as string,
              value: v.value || '-',
              order: 0,
            };
          }

          if (v.key === defaultValues.mileage?.key) {
            return {
              key: v.key,
              name: v.name as string,
              value: `${v.value} км`,
              order,
            };
          }

          if (v.key === defaultValues.carEngineValue.key) {
            return {
              key: v.key,
              name: v.name,
              value: `${v.value} см3`,
              order,
            };
          }

          if (v.unit === 'money') {
            const value =
              v.value.currency === 'RUB'
                ? rub.format(v.value.value)
                : v.value.currency === 'KRW'
                ? krw.format(v.value.value)
                : `${v.value.value} ${v.value.currency}`;

            return {
              key: v.key,
              name: v.name as string,
              value,
              order,
            };
          }
          if (v.unit === 'number') {
            return {
              key: v.key,
              name: v.name as string,
              value: v.value,
              order,
            };
          }
          if (v.unit === 'percent') {
            return {
              key: v.key,
              name: v.name as string,
              value: `${v.value} %`,
              order,
            };
          }
        }
        return undefined;
      })
      .filter((a) => a !== undefined)
      // @ts-ignore
      .sort((a, b) => a.order - b.order)
  );
};

export const sortByDate = (
  a: CarPriceCalculateForm,
  b: CarPriceCalculateForm
) => moment(b.moment).diff(moment(a.moment));

export const makeTKSUrlBycalculate = (
  calculate: CarPriceCalculateForm,
  externalForms: FormValue<number>[]
) => {
  const url = new URL(TKS_HOST);

  url.searchParams.set('calculate_id', calculate.id);
  url.searchParams.set(
    'price',
    calcCarUSDPrice(calculate, externalForms).carKoreaPriceUSD.toString()
  );
  url.searchParams.set('engine', calculate.carEngineType);
  url.searchParams.set('value', calculate.carEngineValue.value.toString());
  url.searchParams.set('year', calculate.carYear.value.toString());
  url.searchParams.set('power', '150');

  return url;
};

export const setTKS = (
  calculate: CarPriceCalculateForm,
  value: number
): CarPriceCalculateForm => {
  return {
    ...calculate,
    TKS: {
      ...calculate.TKS,
      value: {
        ...calculate.TKS.value,
        value,
      },
    },
  };
};
