import React, { useEffect, useState } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

import { setTKS } from '../data/app/app.actions';
import useCalculates from '../data/app/useCalculates';
import { useQuery } from '../hooks/useQuery';
import { CALCULATE_ID_QUERY_PARAM, TKS_QUERY_PARAM } from '../data/common';


export default function CalculatesUpdatePage() {
  const tks = useQuery().get(TKS_QUERY_PARAM);
  const calculateId = useQuery().get(CALCULATE_ID_QUERY_PARAM);

  const { update, getByID } = useCalculates();

  const [isLoading, setIsLoading] = useState(true);

  const setIsLoadingTimeout = (b: boolean) =>
    setTimeout(() => setIsLoading(b), 500);

  useEffect(() => {
    if (calculateId && tks) {
      getByID(calculateId).then((calculate) => {
        if (calculate) {
          update(setTKS(calculate, Number.parseInt(tks))).then(() => {
            setIsLoadingTimeout(false);
          });
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateId]);

  return (
    <div className='flex flex-wrap w-full'>
      {isLoading && (
        <div className='flex justify-content-center w-full mt-8 gap-5 flex-column align-items-center'>
          <ProgressSpinner />
          <div>Обновление...</div>
        </div>
      )}
      {!isLoading && (
        <div className='flex justify-content-center w-full mt-8 gap-5 flex-column align-items-center'>
          <div>Обновление завершено...</div>
          <div>Можно закрыть вкладку</div>
        </div>
      )}
    </div>
  );
}
