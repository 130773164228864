import React, { PropsWithChildren, createContext } from 'react';

import { sha256 } from 'js-sha256';

import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { APPLICATION_KEY } from '../common';
import {
  AuthenticationActions,
  AuthenticationData,
  AuthenticationDataDefault,
  OAuth2Provider,
} from './authentication.types';
import { users } from './users';

export const AuthenticationContext = createContext<AuthenticationData>(
  AuthenticationDataDefault
);

const SESSION_APPLICATION_KEY = `${APPLICATION_KEY}.session`;

const getPasswordHash = (s: string): string => {
  let hash = sha256(s);
  for (let i = 0; i < s.length * 10000; i++) {
    hash = sha256(hash);
  }
  return hash;
};

export const AuthenticationProvider = ({ children }: PropsWithChildren) => {
  const [authenticationData, setAuthenticationData] =
    useLocalStorageState<AuthenticationData>(SESSION_APPLICATION_KEY);

  const actions: AuthenticationActions = {
    signIn: (provider: OAuth2Provider, code?: string): Promise<boolean> => {
      if (provider === 'key' && !code) {
        return Promise.resolve(false);
      }

      if (provider === 'key' && code) {
        const hash = getPasswordHash(code);
        const user = users.find((u) => u.passwordHash === hash);
        if (user?.session) {
          setAuthenticationData({
            ...AuthenticationDataDefault,
            status: 'authenticated',
            session: user.session,
          });
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }

      return Promise.resolve(false);
    },
    signOut: () => {
      setAuthenticationData(undefined);
    },
  };

  return (
    <AuthenticationContext.Provider
      value={{
        ...(authenticationData ?? AuthenticationDataDefault),
        ...actions,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
