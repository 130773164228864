import { Dispatch, SetStateAction, useState } from 'react';

export const setStateToLocalStorage = <T extends object>(
  state: T,
  key: string
) => {
  if (typeof window !== 'undefined') {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (e) {
      return;
    }
  }
};

export const removeStateFromLocalStorage = (key: string) => {
  if (typeof window !== 'undefined') {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return;
    }
  }
};

export const getStateFromLocalStorage = <T extends object>(
  key: string
): T | undefined => {
  if (typeof window !== 'undefined') {
    try {
      const stringState = localStorage.getItem(key);
      if (!stringState) {
        return undefined;
      }
      return JSON.parse(stringState);
    } catch (e) {
      return undefined;
    }
  }
  return undefined;
};

const useLocalStorageDispatch =
  <T extends object>(
    dispatch: Dispatch<SetStateAction<T | undefined>>,
    key: string
  ) =>
  (value: T | undefined) => {
    if (!value) {
      removeStateFromLocalStorage(key);
    } else {
      setStateToLocalStorage<T>(value, key);
    }
    dispatch(value);
  };

const useLocalStorageState = <T extends object>(
  key: string,
  initData?: T | undefined
): [T | undefined, Dispatch<T | undefined>] => {
  const readDataFromStorage = (): T | undefined => {
    const data = getStateFromLocalStorage<T>(key);

    // @ts-ignore
    if (!data || (initData?.version && data.version !== initData.version)) {
      return initData ?? undefined;
    } else {
      return data;
    }
  };

  const [state, setState] = useState<T | undefined>(readDataFromStorage());

  return [state, useLocalStorageDispatch(setState, key)];
};

export { useLocalStorageState };
