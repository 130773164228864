import { FC } from 'react';

import {
  CarPriceCalculateForm,
  FormValue,
  Money,
} from '../../data/app/app.types';

interface CalculateFormulaProps {
  calculate: CarPriceCalculateForm;
}

export const CalculateFormula: FC<CalculateFormulaProps> = ({ calculate }) => {
  function renderBlock<T>(data: FormValue<T>) {
    return (
      <div className='flex flex-column gap-2 align-items-center'>
        <span>{data?.name}</span>
        <span className='font-bold'>
          {data?.unit === 'money'
            ? (data?.value as Money).currency
            : data?.unit === 'percent'
            ? '%'
            : data?.unit === 'number'
            ? 'число'
            : data?.unit}
        </span>
      </div>
    );
  }

  const renderElement = (el: string) => <span className='font-bold'>{el}</span>;

  return (
    <div className='flex gap-3 align-items-center flex-wrap'>
      {renderElement('(')}
      {renderBlock(calculate.carKoreaPrice)}
      {renderElement('+')}
      {renderBlock(calculate.koreaLogisticPrice)}
      {renderElement(')')}
      {renderElement('/')}
      {renderBlock(calculate.KRWtoUSD)}
      {renderElement('*')}
      {renderBlock(calculate.koreaBankComission)}
      {renderElement('*')}
      {renderBlock(calculate.USDtoRUB)}
      {renderElement('+')}
      {renderBlock(calculate.vladivostokLogisticPrice)}
      {renderElement('+')}
      {renderBlock(calculate.spbCommission)}
      {renderElement('+')}
      {renderBlock(calculate.TKS)}
      {renderElement('=')}
      {renderBlock(calculate.total)}
    </div>
  );
};
