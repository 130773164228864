export const routes = {
  calculates: {
    path: '/',
    pathToCalculate: (id: string) => `?calculate=${id}`,
    pathToCalculateCreate: '/new',
    pathToCalculateUpdate: '/update',
  },
  login: {
    path: '/login',
    withRedirect: (pathname: string) =>
      `${routes.login.path}?return_url=${pathname}`,
  },
};
