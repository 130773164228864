import React, { FC, useRef, useState } from 'react';

import html2canvas from 'html2canvas';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { classNames } from 'primereact/utils';

import { getTotalTable } from '../../data/app/app.actions';
import { CarPriceCalculateForm } from '../../data/app/app.types';

interface CalculateTotalsProps {
  calculate: CarPriceCalculateForm;
}

export const CalculateTotals: FC<CalculateTotalsProps> = ({ calculate }) => {
  const [fieldsHided, setFieldsHided] = useState(false);
  const imageRef = useRef(null);

  const data = getTotalTable(calculate, fieldsHided);

  async function saveImage() {
    const element = imageRef.current;
    if (element) {
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL('image/jpg');
      const link = document.createElement('a');

      link.href = data;
      link.download = `${calculate.id}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  const renderCalculatetotalsHeader = () => {
    const icon = fieldsHided ? 'pi pi-eye-slash' : 'pi pi-eye';
    const tip = fieldsHided ? 'Открыть поля' : 'Скрыть поля';

    return (
      <div className='flex align-items-center gap-2'>
        <span>Итоги</span>
        <div>
          <Button
            rounded
            text
            icon={icon}
            tooltip={tip}
            onClick={() => setFieldsHided(!fieldsHided)}
          />
          <Button
            rounded
            text
            icon='pi pi-image'
            tooltip='Сохранить расчет как картинку'
            onClick={() => saveImage()}
          />
        </div>
      </div>
    );
  };

  const nameBodyTemplate = (data: {
    key: string;
    name: string;
    value: string;
    order: number;
  }) => (
    <span
      className={classNames({
        'font-bold': data.key === 'total',
      })}
    >
      {data.name}
    </span>
  );

  return (
    <Fieldset legend={renderCalculatetotalsHeader()}>
      <div ref={imageRef}>
        <DataTable value={data} size='small'>
          <Column field='name' header='Позиция' body={nameBodyTemplate} />
          <Column field='value' header='Значение'></Column>
        </DataTable>
      </div>
    </Fieldset>
  );
};
