import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';

import useCalculates from '../data/app/useCalculates';
import {
  CAR_KOREA_PRICE_QUERY_PARAM,
  DESCRIPTION_QUERY_PARAM,
} from '../data/common';
import { useQuery } from '../hooks/useQuery';
import { routes } from '../routes';

export default function CalculatesCreateNewPage() {
  const carKoreaPrice = useQuery().get(CAR_KOREA_PRICE_QUERY_PARAM);
  const description = useQuery().get(DESCRIPTION_QUERY_PARAM);
  const history = useHistory();

  const { createNew } = useCalculates();

  const [isLoading, setIsLoading] = useState(true);

  const setIsLoadingOff = (id: string) =>
    setTimeout(() => {
      setIsLoading(false);
      history.push(`${routes.calculates.path}?calculate=${id}`);
    }, 500);

  useEffect(() => {
    createNew(carKoreaPrice, description).then((id) => {
      if (id) {
        setIsLoadingOff(id);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='flex flex-wrap w-full'>
      {isLoading && (
        <div className='flex justify-content-center w-full mt-8 gap-5 flex-column align-items-center'>
          <ProgressSpinner />
          <div>Создание заказа...</div>
        </div>
      )}
    </div>
  );
}
