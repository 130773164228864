import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { InputText } from 'primereact/inputtext';

import { useSession } from '../data/authentication/useSession';
import { useQuery } from '../hooks/useQuery';
import { routes } from '../routes';

export default function LoginPage() {
  const { signIn } = useSession();
  const returnUrl = useQuery().get('return_url');
  const history = useHistory();

  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [isProgress, setIsProgress] = useState(false);

  const login = () => {
    setIsProgress(true);

    signIn('key', code)
      .then((result) => {
        if (result) {
          if (returnUrl) {
            history.push(returnUrl);
          } else {
            history.push(routes.calculates.path);
          }
        } else {
          setTimeout(() => {
            setError(true);
          }, 1000);
        }
      })
      .catch(() =>
        setTimeout(() => {
          setError(true);
        }, 1000)
      )
      .finally(() => {
        setTimeout(() => {
          setIsProgress(false);
        }, 1000);
      });
  };

  return (
    <div className='flex w-full justify-content-center'>
      <Card
        title='Вход'
        subTitle='Введите свой ключ доступа сотрудника для авторизации'
        className='lg:col-8 md:col-10 sm:col-12'
      >
        <div className='p-inputgroup'>
          <span className='p-inputgroup-addon'>
            <i className='pi pi-user'></i>
          </span>
          <InputText
            placeholder='Код сотрудника'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            disabled={!code}
            icon={isProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
            className='p-button-success'
            onClick={login}
          />
        </div>
        {error && (
          <div className='mt-3 text-red-500'>
            <small>Вы ввели неверный код сотрудника</small>
          </div>
        )}
      </Card>
    </div>
  );
}
