import { DispatchWithoutAction } from 'react';

export interface Session {
  id?: string;
  name?: string;
  email?: string;
  image?: string;
}

export type AuthStatus =
  | 'authenticated'
  | 'unauthenticated'
  | 'loading'
  | undefined;

export type OAuth2Provider = 'key' | 'google' | 'github';

export type SignInDispatch = (
  provider: OAuth2Provider,
  code?: string
) => Promise<boolean>;

export interface AuthenticationActions {
  signOut: DispatchWithoutAction;
  signIn: SignInDispatch;
}

export interface AuthenticationData extends AuthenticationActions {
  session?: Session;
  status: AuthStatus;
}

export const AuthenticationDataDefault: AuthenticationData = {
  status: 'unauthenticated',
  session: undefined,
  signOut: () => {},
  signIn: () => Promise.resolve(false),
};
