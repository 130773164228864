import './global.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-blue/theme.css';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { PrimeReactProvider } from 'primereact/api';
import setupIndexedDB from 'use-indexeddb';

import Layout from './components/layout/Layout';
import { PrivateRoute } from './components/layout/PrivateRoute';
import { AuthenticationProvider } from './data/authentication/authentication';
import { idbConfig } from './data/db';
import CalculatesCreateNewPage from './pages/CalculatesCreateNewPage';
import CalculatesPage from './pages/CalculatesPage';
import CalculatesUpdatePage from './pages/CalculatesUpdateNewPage';
import ErrorPage from './pages/ErrorPage';
import LoginPage from './pages/LoginPage';
import reportWebVitals from './reportWebVitals';
import { routes } from './routes';

function App() {
  useEffect(() => {
    setupIndexedDB(idbConfig)
      .then(() => console.log('[db] init success'))
      .catch((e) => console.error('[db] error / unsupported', e));
  }, []);

  return (
    <PrimeReactProvider>
      <AuthenticationProvider>
        <Layout>
          <Router>
            <Switch>
              <PrivateRoute
                exact
                path={routes.calculates.path}
                component={CalculatesPage}
              />
              <PrivateRoute
                path={routes.calculates.pathToCalculateCreate}
                component={CalculatesCreateNewPage}
              />
              <PrivateRoute
                path={routes.calculates.pathToCalculateUpdate}
                component={CalculatesUpdatePage}
              />
              <Route path={routes.login.path} component={LoginPage} />
              <Route path='*'>
                <ErrorPage />
              </Route>
            </Switch>
          </Router>
        </Layout>
      </AuthenticationProvider>
    </PrimeReactProvider>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
