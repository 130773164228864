import React, {
  Dispatch,
  DispatchWithoutAction,
  FC,
  ReactNode,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import moment from 'moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';

import { sortByDate } from '../../data/app/app.actions';
import { CarPriceCalculateForm, FormValue } from '../../data/app/app.types';
import { routes } from '../../routes';


const renderCalculate = (calculate: CarPriceCalculateForm) => {
  const carEngineType =
    calculate.carEngineType === 'petrol'
      ? 'Бензин'
      : calculate.carEngineType === 'diesel'
      ? 'Дизель'
      : calculate.carEngineType === 'petrol_electric'
      ? 'Бензин+Э'
      : calculate.carEngineType === 'diesel_electric'
      ? 'Дизель+Э'
      : calculate.carEngineType === 'electro'
      ? 'Электро'
      : calculate.carEngineType;

  return (
    <div
      className='surface-300 p-2 border-round flex flex-column gap-1'
      key={`renderCalculate_${calculate.id}`}
    >
      <span>{moment(calculate.moment).local().format('DD.MM.YYYY HH:mm')}</span>
      <div className='flex gap-1'>
        <span className='font-bold'>{calculate.description?.value}</span>
        <span>{carEngineType}</span>
        <span>{calculate.carYear?.value}</span>
      </div>
      <Link
        key={`renderCalculate_${calculate.id}`}
        to={routes.calculates.pathToCalculate(calculate.id)}
      >
        {calculate.id}
      </Link>
    </div>
  );
};

interface CalculatesListProps {
  calculates?: CarPriceCalculateForm[];
  createNew: DispatchWithoutAction;
  externalForms?: FormValue<number>[];
  setExternalForms: Dispatch<FormValue<number>[]>;
}

export const CalculatesList: FC<CalculatesListProps> = ({
  calculates,
  createNew,
  externalForms,
  setExternalForms,
}) => {
  const [isShortList, setIsShortList] = useState(true);

  const setNumberData = (key: string, value: any) => {
    if (externalForms) {
      const newForm = externalForms.find((f) => f.key === key);

      if (newForm) {
        setExternalForms([
          ...externalForms.filter((f) => f.key !== key),
          {
            ...newForm,
            value,
          },
        ]);
      }
    }
  };

  const renderField = (formValue: FormValue<number>, form: ReactNode) => (
    <div
      className='flex flex-column gap-2'
      key={`renderField_${formValue.key}`}
    >
      <label htmlFor={formValue.key}>{formValue.name ?? ''}</label>
      <div className='p-inputgroup'>{form}</div>
    </div>
  );

  const renderExtrenalField = () => (
    <Fieldset legend='Валюты'>
      <div className='flex flex-column gap-5'>
        {externalForms
          ?.sort((a, b) => (a.order ?? 50) - (b.order ?? 50))
          .map((form) =>
            renderField(
              form,
              <InputNumber
                id={form.key}
                placeholder={form?.name}
                value={form?.value}
                onChange={(e) => {
                  if (e.value) {
                    setNumberData(form.key, e.value);
                  }
                }}
              />
            )
          )}
      </div>
    </Fieldset>
  );

  const renderData = () => {
    if (!calculates?.length) {
      return <div>Нет расчетов</div>;
    }
    if (calculates) {
      return (
        <div className='flex flex-column gap-2'>
          {isShortList &&
            calculates.sort(sortByDate).slice(0, 5).map(renderCalculate)}
          {!isShortList && calculates.sort(sortByDate).map(renderCalculate)}
          {calculates.length > 5 && (
            <div className='flex justify-content-center'>
              <Button
                icon={isShortList ? 'pi pi-angle-down' : 'pi pi-angle-up'}
                rounded
                text
                onClick={() => setIsShortList(!isShortList)}
              />
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <Card
      title='Список заказов'
      subTitle='Ранее посчитанные заказы'
      className='min-h-full'
    >
      <div className='flex flex-column gap-5'>
        {renderData()}
        <Button
          label='Создать новый расчет'
          onClick={createNew}
          className='mt-auto'
        />
        {renderExtrenalField()}
      </div>
    </Card>
  );
};
