import { useEffect, useState } from 'react';

import moment from 'moment';
import { useIndexedDBStore } from 'use-indexeddb';

import { CALCULATES_STORE } from '../db';
import {
  CarPriceCalculateForm,
  CarPriceCalculateFormDefault,
} from './app.types';

interface UseCalculatesData {
  calculates?: CarPriceCalculateForm[];
  createNew: (
    carKoreaPrice?: string | null,
    description?: string | null
  ) => Promise<string | null>;
  update: (calculate: CarPriceCalculateForm) => Promise<void>;
  deleteByID: (id: string) => Promise<any>;
  getByID: (id: string) => Promise<CarPriceCalculateForm>;
}

const useCalculates = (): UseCalculatesData => {
  const { getAll, add, update, deleteByID, getByID } =
    useIndexedDBStore<CarPriceCalculateForm>(CALCULATES_STORE);

  const [calculates, setCalculates] = useState<CarPriceCalculateForm[]>();

  useEffect(() => {
    refreshAll().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAll = async () => {
    try {
      const data = await getAll();
      return setCalculates(data);
    } catch (e) {
      return console.error('[db] refresh all calculates error', e);
    }
  };

  const createNewHandler = async (
    carKoreaPrice?: string | null,
    description?: string | null
  ) => {
    console.log('[db] create new calculate');

    try {
      const data = CarPriceCalculateFormDefault();
      if (carKoreaPrice) {
        data.carKoreaPrice.value.value = +carKoreaPrice;
      }
      if (description) {
        data.description.value = description;
      }
      await add(data);
      setCalculates([...(calculates ?? []), data]);

      return data.id;
    } catch (e) {
      console.error('[db] create new calculate error', e);
      return null;
    }
  };

  const updateNewHandler = async (data: CarPriceCalculateForm) => {
    const newData = {
      ...data,
      moment: moment().toString(),
    };
    try {
      await update(newData);
      setCalculates([
        ...(calculates ?? []).filter((c) => c.id !== newData.id),
        newData,
      ]);
    } catch (e) {
      console.error('[db] update calculate error', e);
    }
  };

  const deleteByIDHandler = async (id: string) => {
    try {
      await deleteByID(id);
      setCalculates([...(calculates ?? []).filter((c) => c.id !== id)]);
    } catch (e) {
      console.error('[db] delete calculate error', e);
    }
  };

  return {
    calculates,
    createNew: createNewHandler,
    update: updateNewHandler,
    deleteByID: deleteByIDHandler,
    getByID,
  };
};

export default useCalculates;
