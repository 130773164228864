import { APPLICATION_KEY } from './common';

export const CALCULATES_STORE = 'calculates';

export const idbConfig = {
  databaseName: APPLICATION_KEY,
  version: 1,
  stores: [
    {
      name: CALCULATES_STORE,
      id: { keyPath: 'id', autoIncrement: false },
      indices: [],
    },
  ],
};
