import React, { Dispatch, FC, ReactNode, useState } from 'react';

import moment from 'moment';
import { Button } from 'primereact/button';
import { Fieldset } from 'primereact/fieldset';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from 'primereact/inputtextarea';

import { makeTKSUrlBycalculate } from '../../data/app/app.actions';
import {
  CarPriceCalculateForm,
  FormValue,
  Money,
} from '../../data/app/app.types';

interface CalculatorFormProps {
  calculate: CarPriceCalculateForm;
  setCalculate: Dispatch<CarPriceCalculateForm>;
  externalForms: FormValue<number>[];
}

export const CalculatorForms: FC<CalculatorFormProps> = ({
  calculate,
  setCalculate,
  externalForms,
}) => {
  // DATA
  const [fieldsLocked, setFieldsLocked] = useState(true);

  // ACTIONS
  const setSimpleData = (field: string, value: any) => {
    if (calculate) {
      setCalculate({
        ...calculate,
        [field]: {
          // @ts-ignore
          ...calculate[field],
          value,
        },
      });
    }
  };

  const setMoneyData = (field: string, value: any) => {
    if (calculate) {
      setCalculate({
        ...calculate,
        [field]: {
          // @ts-ignore
          ...calculate[field],
          value: {
            // @ts-ignore
            ...calculate[field].value,
            value,
          },
        },
      });
    }
  };

  // RENDERS
  const renderDescription = () => {
    return (
      <>
        <span className='p-inputgroup-addon'>
          <i className='pi pi-book'></i>
        </span>
        <InputTextarea
          placeholder={calculate.description.name}
          value={calculate.description.value}
          onChange={(e) => {
            if (e.target.value) {
              setSimpleData('description', e.target.value);
            }
          }}
        />
      </>
    );
  };

  const renderYear = () => {
    return (
      <>
        <span className='p-inputgroup-addon'>
          <i className='pi pi-chevron-circle-down'></i>
        </span>
        <InputNumber
          placeholder={calculate.carYear.name}
          value={calculate.carYear.value}
          showButtons
          min={moment().year() - 10}
          max={moment().year()}
          useGrouping={false}
          onChange={(e) => {
            if (e.value) {
              setSimpleData('carYear', e.value);
            }
          }}
        />
      </>
    );
  };

  const renderMileage = () => {
    if (!calculate.mileage) {
      return null;
    }

    return (
      <>
        <span className='p-inputgroup-addon'>
          <i className='pi pi-chevron-circle-down'></i>
        </span>
        <InputNumber
          id='mileage'
          placeholder={calculate.mileage.name}
          value={calculate.mileage.value}
          min={0}
          useGrouping={true}
          suffix=' км'
          onChange={(e) => {
            if (e.value) {
              setSimpleData('mileage', e.value);
            }
          }}
        />
      </>
    );
  };

  const renderEngineValue = () => {
    return (
      <>
        <span className='p-inputgroup-addon'>
          <i className='pi pi-cog'></i>
        </span>
        <InputNumber
          id='carEngineValue'
          placeholder={calculate.carEngineValue.name}
          value={calculate.carEngineValue?.value ?? 2990}
          min={0}
          showButtons
          suffix=' см3'
          onChange={(e) => {
            if (e.value) {
              setSimpleData('carEngineValue', e.value);
            }
          }}
        />
      </>
    );
  };

  const renderPriceField = (
    data: FormValue<Money>,
    field: string,
    forceDisabled?: boolean,
    renderButton?: () => React.JSX.Element
  ) => {
    return (
      <>
        <span className='p-inputgroup-addon'>
          <i className='pi pi-car'></i>
        </span>
        <InputNumber
          id={field}
          placeholder={data?.name}
          value={data?.value.value}
          mode='currency'
          currency={data?.value?.currency}
          onChange={(e) => {
            if (e.value) {
              setMoneyData(field, e.value);
            }
          }}
          disabled={forceDisabled ? fieldsLocked : false}
        />
        {renderButton && renderButton()}
      </>
    );
  };

  const renderField = (field: string, form: ReactNode) => {
    // @ts-ignore
    if (calculate[field] === undefined) {
      return null;
    }

    return (
      <div className='flex gap-2 align-items-center'>
        <label htmlFor={field} className='w-20rem'>
          {/*// @ts-ignore*/}
          {calculate[field].name ?? ''}
        </label>
        <div className='p-inputgroup'>{form}</div>
      </div>
    );
  };

  const renderTKSCalcButton = () => (
    <Button
      label='Расчет'
      onClick={() =>
        window.open(makeTKSUrlBycalculate(calculate, externalForms), '_blank')
      }
    />
  );

  const renderCarSettingHeader = () => {
    const icon = fieldsLocked ? 'pi pi-lock' : 'pi pi-unlock';
    const tip = fieldsLocked ? 'Разблокировать поля' : 'Заблокировать поля';

    return (
      <div className='flex align-items-center gap-2'>
        <span>Данные машины</span>
        <Button
          rounded
          text
          icon={icon}
          tooltip={tip}
          onClick={() => setFieldsLocked(!fieldsLocked)}
          severity={!fieldsLocked ? 'danger' : undefined}
        />
      </div>
    );
  };

  return (
    <Fieldset legend={renderCarSettingHeader()}>
      <div className='flex flex-column gap-3'>
        <div className='flex flex-column gap-5'>
          {renderField('description', renderDescription())}
          {renderField('carYear', renderYear())}
          {renderField('mileage', renderMileage())}
          {renderField('carEngineValue', renderEngineValue())}
          {renderField(
            'carKoreaPrice',
            renderPriceField(calculate.carKoreaPrice, 'carKoreaPrice')
          )}
          {renderField(
            'koreaLogisticPrice',
            renderPriceField(
              calculate.koreaLogisticPrice,
              'koreaLogisticPrice',
              true
            )
          )}
          {renderField(
            'vladivostokLogisticPrice',
            renderPriceField(
              calculate.vladivostokLogisticPrice,
              'vladivostokLogisticPrice',
              true
            )
          )}
          {renderField(
            'spbCommission',
            renderPriceField(calculate.spbCommission, 'spbCommission', true)
          )}
          {renderField(
            'TKS',
            renderPriceField(calculate.TKS, 'TKS', false, renderTKSCalcButton)
          )}
        </div>
      </div>
    </Fieldset>
  );
};
