import moment from 'moment';

import { uuidv4 } from '../../utils/uuid';

export interface Money {
  value: number;
  currency: 'USD' | 'EUR' | 'RUB' | 'KRW';
}

export interface CarPriceCalculateForm {
  id: string;
  moment: string;

  description: FormValue<string>;
  mileage?: FormValue<number>;

  carYear: FormValue<number>;
  carEngineValue: FormValue<number>;
  carEngineType:
    | 'diesel'
    | 'petrol'
    | 'electro'
    | 'petrol_electric'
    | 'diesel_electric';

  carKoreaPrice: FormValue<Money>;
  koreaLogisticPrice: FormValue<Money>;
  vladivostokLogisticPrice: FormValue<Money>;
  spbCommission: FormValue<Money>;
  TKS: FormValue<Money>;
  koreaBankComission: FormValue<number>;

  KRWtoUSD: FormValue<number>;
  USDtoRUB: FormValue<number>;

  total: FormValue<Money>;
}

export interface FormValue<T> {
  value: T;
  key:
    | 'total'
    | 'mileage'
    | 'description'
    | 'carEngineValue'
    | 'TKS'
    | 'koreaBankComission'
    | 'carYear'
    | 'carKoreaPrice'
    | 'spbCommission'
    | 'koreaLogisticPrice'
    | 'vladivostokLogisticPrice'
    | 'KRWtoUSD'
    | 'USDtoRUB';
  name: string;
  type: 'string' | 'number' | 'external';
  unit: 'money' | 'percent' | 'number' | 'text' | 'km';
  order: number;
}

export const CarPriceCalculateFormDefault = (): CarPriceCalculateForm => ({
  id: uuidv4(),
  moment: moment().toString(),
  total: {
    key: 'total',
    name: 'Итоговая цена автомобиля под ключ',
    value: {
      value: 0,
      currency: 'RUB',
    },
    type: 'number',
    unit: 'money',
    order: 100,
  },
  description: {
    key: 'description',
    name: 'Автомобиль',
    value: '',
    type: 'string',
    unit: 'text',
    order: 0,
  },
  carYear: {
    key: 'carYear',
    value: moment().subtract(1, 'year').year(),
    name: 'Год автомобиля',
    type: 'number',
    unit: 'number',
    order: 1,
  },
  mileage: {
    key: 'mileage',
    name: 'Пробег автомобиля',
    value: 0,
    type: 'number',
    unit: 'km',
    order: 2,
  },
  carEngineValue: {
    key: 'carEngineValue',
    value: 2990,
    name: 'Объём двигателя',
    type: 'number',
    unit: 'number',
    order: 3,
  },
  carEngineType: 'petrol',
  carKoreaPrice: {
    key: 'carKoreaPrice',
    value: { value: 10_000_000, currency: 'KRW' },
    name: 'Цена автомобиля в Корее',
    type: 'number',
    unit: 'money',
    order: 4,
  },
  koreaBankComission: {
    key: 'koreaBankComission',
    name: 'Комиссия банка',
    value: 0,
    type: 'number',
    unit: 'percent',
    order: 50,
  },
  KRWtoUSD: KRWtoUSDDefault,
  USDtoRUB: USDtoRUBDefault,
  koreaLogisticPrice: {
    key: 'koreaLogisticPrice',
    value: {
      value: 3_440_000,
      currency: 'KRW',
    },
    name: 'Все расходы по Корее',
    type: 'number',
    unit: 'money',
    order: 7,
  },
  spbCommission: {
    key: 'spbCommission',
    value: {
      value: 50_000,
      currency: 'RUB',
    },
    name: 'Цена услуги CarexKorea',
    type: 'number',
    unit: 'money',
    order: 8,
  },
  vladivostokLogisticPrice: {
    key: 'vladivostokLogisticPrice',
    value: {
      value: 100_000,
      currency: 'RUB',
    },
    name: 'Все расходы по Владивостоку',
    type: 'number',
    unit: 'money',
    order: 9,
  },
  TKS: {
    key: 'TKS',
    name: 'Таможенные платежи',
    value: {
      value: 0,
      currency: 'RUB',
    },
    type: 'external',
    unit: 'money',
    order: 10,
  },
});

export const KRWtoUSDDefault: FormValue<number> = {
  key: 'KRWtoUSD',
  name: 'Курс KRW к USD',
  value: 0,
  type: 'external',
  unit: 'number',
  order: 5,
};

export const USDtoRUBDefault: FormValue<number> = {
  key: 'USDtoRUB',
  name: 'Курс USD к RUB',
  value: 0,
  type: 'external',
  unit: 'number',
  order: 6,
};
// Формула для расчёта автомобиля от 3 до 5 лет:
//
//   (Цена авто в вон+3 440 000 вон(логистика по Корее)) / 1315 (это курс к юаню) USD
// К этому итогу +2% комиссия банка
// конвертация к рублю
// +100 000 расходы Владик
// +50 000 наша комиссия
// +ТКС
