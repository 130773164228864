import styles from './Layout.module.scss';

import React from 'react';

import { useSession } from '../../data/authentication/useSession';
import { routes } from '../../routes';
import { UserInfo } from './UserInfo';

export function Navigation() {
  const { status, session, signOut } = useSession();

  return (
    <div className={styles.Navigation}>
      <a href={routes.calculates.path} className='no-underline'>
        <div className='lg:text-3xl sm:text-2xl font-bold'>
          Калькулятор стоимости автомобиля
        </div>
      </a>
      <div className={styles.Navigation__menu}>
        <UserInfo status={status} session={session} signOut={signOut} />
      </div>
    </div>
  );
}
