export const APPLICATION_KEY = 'net.kroniak.cars';
export const EXTERNAL_FORMS_KEY = `${APPLICATION_KEY}.extrenal_forms`;
export const TKS_HOST = 'https://www.tks.ru/auto/calc/';

export const TKS_QUERY_PARAM = 'tks';
export const CALCULATE_ID_QUERY_PARAM = 'calculate';
export const CAR_KOREA_PRICE_QUERY_PARAM = 'car_korea_price';
export const DESCRIPTION_QUERY_PARAM = 'description';


