import styles from './Layout.module.scss';

import React, { DispatchWithoutAction, FC, useRef } from 'react';

import { Avatar } from 'primereact/avatar';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { classNames } from 'primereact/utils';

import {
  AuthStatus,
  Session,
} from '../../data/authentication/authentication.types';
import { routes } from '../../routes';

export type UserInfoProps = {
  status: AuthStatus;
  session?: Session;
  signOut: DispatchWithoutAction;
};

export const UserInfo: FC<UserInfoProps> = ({ session, status, signOut }) => {
  const userMenuRef = useRef<Menu>(null);

  const userMenu: MenuItem[] = [
    {
      label: 'Выйти из системы',
      icon: 'pi pi-fw pi-sign-out',
      command: signOut,
    },
  ];

  if (status === undefined) {
    return null;
  }

  if (status === 'unauthenticated') {
    return (
      <a href={routes.login.path}>
        <button
          type='button'
          className={classNames('p-link', styles.Navigation__topbar_button)}
        >
          <i className='pi pi-sign-in'></i>
          <span>Войти</span>
        </button>
      </a>
    );
  }

  if (status === 'authenticated') {
    return (
      <>
        <Avatar
          label={session?.name ? session?.name[0] : 'N/A'}
          size='large'
          style={{ backgroundColor: 'var(--primary-color)', color: '#ffffff' }}
          shape='circle'
          onClick={(event) => userMenuRef?.current?.toggle(event)}
        />
        <Menu model={userMenu} popup ref={userMenuRef} id='popup_menu_left' />
      </>
    );
  }

  return null;
};
