import { Session } from './authentication.types';

interface User {
  session: Session;
  passwordHash: string;
}

export const users: User[] = [
  {
    session: {
      id: 'jsjdsjslllandall343434114',
      name: 'Даша',
    },
    passwordHash: '1fb56dbc560a9b491e8e17f44a0447245f7ab35884d4b08f7275428ea53b0874',
  },
];
