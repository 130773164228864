import React, {
  Dispatch,
  DispatchWithoutAction,
  FC,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';

import moment from 'moment/moment';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Fieldset } from 'primereact/fieldset';
import { Toast, ToastMessage } from 'primereact/toast';

import { calc } from '../../data/app/app.actions';
import { CarPriceCalculateForm, FormValue } from '../../data/app/app.types';
import { CalculateFormula } from './CalculateFormula';
import { CalculateTotals } from './CalculateTotals';
import { CalculatorForms } from './CalculatorForms';

interface CalculateProps {
  calculateInit?: CarPriceCalculateForm;
  save: (calculate: CarPriceCalculateForm) => Promise<void>;
  deleteCalculate: Dispatch<string>;
  externalForms?: FormValue<number>[];
}

const Calculate: FC<CalculateProps> = ({
  calculateInit,
  save,
  deleteCalculate,
  externalForms,
}) => {
  // DATA
  const toast = useRef<Toast>(null);

  const [calculate, setCalculate] = useState<
    CarPriceCalculateForm | undefined
  >();

  const [isProgressing, setIsProgressing] = useState(false);

  // EFFECT
  useEffect(() => {
    setCalculate(calculateInit);
  }, [calculateInit]);

  // HANDLERS
  const setIsProgressingHandler = () =>
    setTimeout(() => setIsProgressing(false), 500);

  const setMessage = (callback: DispatchWithoutAction) =>
    setTimeout(() => callback(), 300);

  const showMessage = (
    summary: string,
    ref: RefObject<Toast>,
    severity: ToastMessage['severity']
  ) => {
    ref.current?.show({
      severity: severity,
      summary: 'Калькулятор расчетов',
      detail: summary,
      life: 3000,
    });
  };

  const calculateHandler = () => {
    if (calculate && externalForms) {
      try {
        setIsProgressing(true);

        const calculateTotal = calc(calculate, externalForms);

        setCalculate(calculateTotal);
        setMessage(() => showMessage('Расчет завершен', toast, 'success'));
        saveHandler(calculateTotal);
      } catch (e) {
        console.error('[core] calculating error', e);
        setMessage(() =>
          showMessage('Расчет завершен ошибкой', toast, 'error')
        );
      } finally {
        setIsProgressingHandler();
      }
    }
  };

  const saveHandler = (saveObjectForce?: CarPriceCalculateForm) => {
    const o = saveObjectForce ?? calculate;

    if (o) {
      setIsProgressing(true);

      save(o)
        .then(() =>
          setMessage(() =>
            showMessage('Сохранение завершено', toast, 'success')
          )
        )
        .catch((e) => {
          console.error('[core] calculating error', e);
          setMessage(() =>
            showMessage('Сохранение завершено с ошибкой', toast, 'error')
          );
        })
        .finally(setIsProgressingHandler);
    }
  };

  // RENDERS

  const renderFooter = () => {
    return (
      <div className='flex gap-3'>
        {calculate && (
          <>
            <Button
              label='Посчитать'
              className='w-14rem'
              onClick={calculateHandler}
              disabled={isProgressing}
            />
            <Button
              label='Сохранить расчет'
              className='w-14rem'
              onClick={() => saveHandler()}
              disabled={isProgressing}
            />
            <Button
              label='Удалить расчет'
              className='w-14rem'
              onClick={() => deleteCalculate(calculate?.id)}
              severity='danger'
            />
          </>
        )}
      </div>
    );
  };

  const renderCalculate = () => {
    if (!calculate) {
      return null;
    }

    return (
      <div className='flex flex-column gap-3'>
        <Fieldset legend='Данные расчета'>
          <div className='flex flex-column gap-3'>
            <span>Расчет: {calculate?.id}</span>
            <span>
              Дата расчета:{' '}
              {moment(calculate.moment).local().format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </Fieldset>
        {externalForms && (
          <CalculatorForms
            calculate={calculate}
            setCalculate={setCalculate}
            externalForms={externalForms}
          />
        )}
        <Fieldset legend='Формула расчета' toggleable collapsed>
          <CalculateFormula calculate={calculate} />
        </Fieldset>
        <CalculateTotals calculate={calculate} />
      </div>
    );
  };

  return (
    <Card
      title='Калькулятор заказа'
      subTitle='Этот калькулятор упрощает жизнь Даше'
      footer={renderFooter()}
    >
      <Toast ref={toast} position='bottom-right' />
      {!calculate && (
        <div className='flex flex-column gap-3'>
          <span>Расчет не загружен</span>
        </div>
      )}
      {renderCalculate()}
    </Card>
  );
};
export default Calculate;
