import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ProgressSpinner } from 'primereact/progressspinner';

import Calculate from '../components/orders/Calculate';
import { CalculatesList } from '../components/orders/CalculatesList';
import { sortByDate } from '../data/app/app.actions';
import { FormValue, KRWtoUSDDefault, USDtoRUBDefault } from '../data/app/app.types';
import useCalculates from '../data/app/useCalculates';
import { CALCULATE_ID_QUERY_PARAM, EXTERNAL_FORMS_KEY } from '../data/common';
import { useLocalStorageState } from '../hooks/useLocalStorageState';
import { useQuery } from '../hooks/useQuery';
import { routes } from '../routes';

export default function CalculatesPage() {
  const calculateId = useQuery().get(CALCULATE_ID_QUERY_PARAM);
  const history = useHistory();

  const { calculates, createNew, update, deleteByID } = useCalculates();
  const [externalForms, setExternalForms] = useLocalStorageState<
    FormValue<number>[]
  >(EXTERNAL_FORMS_KEY, [KRWtoUSDDefault, USDtoRUBDefault]);

  const [isLoading, setIsLoading] = useState(true);

  const currentCalculate = calculates?.find((c) => c.id === calculateId);

  const setIsLoadingTimeout = (b: boolean) =>
    setTimeout(() => setIsLoading(b), 500);

  useEffect(() => {
    if (calculates) {
      if (calculateId === null) {
        if (calculates.length > 0) {
          history.push(
            routes.calculates.pathToCalculate(calculates.sort(sortByDate)[0].id)
          );
        }
      }
      setIsLoadingTimeout(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calculateId, calculates]);

  const createNewHandler = () => {
    createNew(undefined, undefined).then((id) => {
      if (id) {
        history.push(`${routes.calculates.path}?calculate=${id}`);
      }
    });
  };

  return (
    <div className='flex flex-wrap w-full'>
      {isLoading && (
        <div className='flex justify-content-center w-full mt-8 gap-5 flex-column align-items-center'>
          <ProgressSpinner />
          <div>Загрузка...</div>
        </div>
      )}
      {!isLoading && (
        <>
          <div className='xl:col-3 lg:col-4 md:col-12 sm:col-12 flex-shrink-0'>
            <CalculatesList
              calculates={calculates}
              createNew={createNewHandler}
              externalForms={externalForms}
              setExternalForms={setExternalForms}
            />
          </div>
          <main className='xl:col-9 lg:col-8 md:col-12 sm:col-12'>
            <Calculate
              calculateInit={currentCalculate}
              save={update}
              deleteCalculate={deleteByID}
              externalForms={externalForms}
            />
          </main>
        </>
      )}
    </div>
  );
}
