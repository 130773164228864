import styles from './Layout.module.scss';

import React, { FC, PropsWithChildren } from 'react';

import { classNames } from 'primereact/utils';

import { Navigation } from './Navigation';

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='surface-ground min-h-screen'>
      <Navigation />
      <div
        className={classNames(
          styles.Layout__container,
          'flex gap-2 lg:pr-4 lg:pl-4 md:pr-4 md:pl-4 sm:pr-1 sm:pl-1'
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Layout;
