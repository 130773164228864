import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';



import { useSession } from '../../data/authentication/useSession';
import { routes } from '../../routes';


// @ts-ignore
export function PrivateRoute({ component: Component, ...rest }) {
  const { session } = useSession();
  const { pathname, search } = useLocation();

  const redirectTo = pathname
    ? routes.login.withRedirect(`${pathname}${search}`)
    : routes.login.path;

  return (
    <Route
      {...rest}
      render={(props) => {
        // Renders the page only if `user` is present (user is authenticated)
        // Otherwise, redirect to the login page
        return session ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectTo} />
        );
      }}
    ></Route>
  );
}
